// import logo from './logo.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Ariya Mattra
        </p>
        <a>
          Profile
        </a>
      </header>
    </div>
  );
}

export default App;
